import Types from "./Types";

const INIT_STATE = {
  regions: null,
  neighborhoods: null,
  loadRegions: {
    type: "address",
    isLoading: false,
  },
  loadNeighborhoods: {
    type: "address",
    isLoading: false,
  },
};

export default function vacationsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.GET_ADDRESS_REGIONS_SUCCESS: {
      return {
        ...state,
        regions: payload,
      };
    }
    case Types.GET_ADDRESS_REGIONS_LOADING: {
      return {
        ...state,
        loadRegions: payload,
      };
    }
    case Types.GET_ADDRESS_NEIGHBORHOODS_SUCCESS: {
      return {
        ...state,
        neighborhoods: payload,
      };
    }
    case Types.GET_ADDRESS_NEIGHBORHOODS_LOADING: {
      return {
        ...state,
        loadNeighborhoods: payload,
      };
    }
    default: {
      return state;
    }
  }
}
