// const firebaseConfig = {
//   apiKey: "AIzaSyAV7Cf1tBoqwY6iFKTUQoA_D9G9VxPn-MI",
//   authDomain: "mrcarrot-b30f9.firebaseapp.com",
//   projectId: "mrcarrot-b30f9",
//   storageBucket: "mrcarrot-b30f9.appspot.com",
//   messagingSenderId: "94300332652",
//   appId: "1:94300332652:web:d8403e439ad6421593ae49",
//   measurementId: "G-HNVW5CSW5Z",
// };

const firebaseConfig = {
  apiKey: "AIzaSyAK7KV2EdBzqRLpfxY10hf1UeIfrlA8Gyw",
  authDomain: "mr-carrotsa.firebaseapp.com",
  projectId: "mr-carrotsa",
  storageBucket: "mr-carrotsa.firebasestorage.app",
  messagingSenderId: "956761208058",
  appId: "1:956761208058:web:547e58990606a5c3e9c543",
  measurementId: "G-MNZXT6QXNL"
};

// Initialize Firebase
export default firebaseConfig;
