import {
  FooterLogo,
  InovaLogo,
  InstagramIcon,
  LocationIconOrange,
  MailIcon,
  SnapchatIcon,
  TaxIcon,
  TiktokIcon,
  TwitterIcon,
  WebIcon,
  WhatsappIcon,
} from "../../../utils/IconsSrc";
// import { logoPng} from "../../../../public/Partners/sfs_Logo.png"
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

// import Button from "../../Atoms/Buttons/Button";
// import Textfield from "../../Atoms/Textfields/Textfield";

const Footer = ({ t }) => {
  const SocialLinks = [
    {
      text: "311322093900003",
      icon: <TaxIcon className="min-w-[38px]" />,
    },
    {
      text: " mrcarrot.sa",
      icon: <SnapchatIcon className="min-w-[38px]" />,
    },
    {
      text: "mrcarrot.sa",
      href: "https://www.tiktok.com/@mrcarrot.sa",
      icon: <TiktokIcon className="min-w-[38px]" />,
    },
    {
      text: "mrcarrotsa",
      href: "https://twitter.com/mrcarrotsa",
      icon: <TwitterIcon className="min-w-[38px]" />,
    },
    {
      text: "mrcarrot.co",
      href: "",
      icon: <WebIcon className="min-w-[38px]" />,
    },
    {
      text: "mrcarrot.sa@gmail.com",
      icon: <MailIcon className="min-w-[38px]" />,
      href:"mailto:mrcarrot.sa@gmail.com"
    },
    {
      text: "mrcarrot.sa",
      href: "https://www.instagram.com/mrcarrot.sa/",
      icon: <InstagramIcon className="min-w-[38px]" />,
    },
    {
      text: "+966531270787",
      icon: <WhatsappIcon className="min-w-[38px]" />,
      href:"https://wa.me/966531270787"
    },
    {
      text: t("contactUs.location"),
      icon: <LocationIconOrange className="min-w-[38px]" />,
      href:"https://maps.app.goo.gl/8ktEybaFFqUhbwXg6"
    },
  ];

  return (
    <footer>
      <div className="bg-orange">
        <div className="lg:flex   lg:w-[84%] w-[90%] mx-auto py-[40px] new-main-footer ">
          <div className="flex justify-center items-center new-footer-logo">
            <Link to="/">
              <FooterLogo />
            </Link>
          </div>
          <div className="sm:mx-[1%] mx-[5%] space-y-2 new-footer">
            {/* <p className="text-[30px] font-[AraHamah1964] text-white sm:mx-10 new-footer-follow">
              {t("footer.followUs")}
            </p> */}
            <div className="new-footer-banner">
              {SocialLinks.map((link, i) => {
                return (
                  <div className="flex  col-span-1 mt-3 sm:mx-3" key={i}>
                    {/* <TaxIcon className="min-w-[38px]" /> */}

                    <a href={link.href} target="_blank" rel="noreferrer">
                      {link.icon}
                    </a>
                    <p className="text-[22px] font-[AraHamah1964] text-white mx-4">
                      <a href={link.href} target="_blank" rel="noreferrer">
                        {link.text}
                      </a>
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center py-4 items-center footer-logo">
  <p className="text-[23px] font-[AraHamah1964] text-lightgrey mr-2">
    {t("footer.developedBy")}
  </p>
  <a
    href="https://sfswebtech.com/"
    target="_blank"
    rel="noreferrer noopener"
    className="text-[23px] font-[AraHamah1964] text-lightgrey"
  >
    <img
      src={"/Partners/sfs_Logo.png"} // Replace with the actual path or URL of your image
      alt="sfswebtech"
      className="h-6 w-auto" // Adjust the size of the image as needed
    />
  </a>
</div>

    </footer>
  );
};

export default withTranslation()(Footer);
