import Types from "./Types";

//GET ALL BLOGS
export const listBlog = (payload) => ({
  type: Types.LIST_BLOG_SUCCESS,
  payload,
});
export const fixListBlog = (payload) => ({
  type: Types.FIX_LIST_BLOG_LOADING,
  payload,
});
export const listBlogLoading = (payload) => ({
  type: Types.LIST_BLOG_LOADING,
  payload,
});
export const getBlogLoading = (payload) => ({
  type: Types.GET_BLOG_LOADING,
  payload,
})
export const getBlogSuccess = (payload) => ({
  type: Types.GET_BLOG_SUCCESS,
  payload,
});
